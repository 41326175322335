<template>
  <div class="m-1">
    <b-card>
      <filter-swapper v-show="!loading.first" :trigger="selectedRows.length === 0" :buttons="buttons" :controlHeightButtons="controlHeight">
        <template #slot1>
          <form-render :form.sync="form" :key="keyFormRender" :fields="fields" @send="filterList" ref="formFilter"
            containerButtonsClass="col-sm-12 col-md-7 col-lg-4 col-xl-3 container-button mt-2" class="mb-2">
            <template #myButtons>
              <b-button variant="warning" class="mb-0" v-b-tooltip.hover title="Buscar" @click="ok()"><b-spinner small style="vertical-align: top" v-if="loading.searchIcon"/><feather-icon v-else icon="SearchIcon"/></b-button>
              <b-button variant="outline-light" v-if="!($session.get('cas_user').role === 'ecommerce')" v-b-tooltip.hover title="Limpiar filtros" class="ml-1 mb-0" @click="cleanFilter"><feather-icon icon="RefreshCwIcon"/></b-button>
              <b-button v-if="($session.get('cas_user').role === 'ecommerce')" variant="warning" class="mr-1 pr-4 pl-4 push-right" @click="createAdvancedRuleShipper"><feather-icon icon="PlusIcon" class="mr-05"/>{{$t('Crear regla')}}</b-button>
              <b-dropdown v-else class="push-right" variant="warning">
                <template #button-content><feather-icon icon="PlusIcon" class="mr-05"/>{{$t('Crear regla')}}</template>
                <b-dropdown-item v-for="(button, index) of buttonsDropDown" :key="index"
                 @click="executeAction(button)"><feather-icon v-if="button.icon" :icon="button.icon"/>{{ $t(button.text) || '' }} 
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </form-render>
        </template>
      </filter-swapper>
      <div v-show="(!loading.advancedRules || !loading.advancedRulesByOrganization) && !loading.first && !loading.second">
        <table-render :schema="schema" :rows="rows" :actions="actions" :loading="loading.advancedRules || loading.advancedRulesByOrganization" :selectedRows.sync="selectedRows" :useToolTip="['ecommerce', 'seller'].includes($session.get('cas_user').role) ? false : true">
          <template #status="scope">
            <div>
              <span v-if="['ecommerce', 'seller'].includes($session.get('cas_user').role)" :class="`table_dot--state ${scope.rowdata.status_active.id < 5 ? 'table_dot--state-active' : 'table_dot--state-creado' }`">
              </span>
              <span v-else :class="`table_dot--state ${scope.rowdata.status_active.dot}`"></span> 
              {{ ['ecommerce', 'seller'].includes($session.get('cas_user').role) ? scope.rowdata.status_active.text.split(' ')[0] : scope.rowdata.status_active.text }}
            </div>
          </template>
          <template #assigned="scope">
            <div>
              <b-button variant="link" class="p-0 lnk underline-text-deliveries" @click="openModal('modalInfoRule', scope.rowdata, false)">
                {{ scope.rowdata.rule_status != 1 ? `${scope.rowdata.actives_rules_shippers.active_count} ${scope.rowdata.actives_rules_shippers.active_count === 1 ? 'Empresa' : 'Empresas' }` : 'Todo el marketplace' }}
              </b-button>
            </div>
          </template>
        </table-render>
        <pagination :pagination="pagination" :noDigits="true" :showSize="true"/>
      </div>
      <b-skeleton type="input" v-if="loading.first" class="mb-2 mt-2 spacing-label-field" />
      <div class="table-render-skeleton" v-if="(loading.advancedRules || loading.advancedRulesByOrganization) && !loading.first && loading.second">
        <b-skeleton-table
          :rows="pagination.limit || 5"
          :columns="schema.length || 10"
          :table-props="{ }"/>
      </div>
    </b-card>
    <modal-new-rule :form.sync="currentRule" @send="savedRule"></modal-new-rule>
    <modal-info-rule :dataRule="dataRule" :isDelete="isDelete"
    ref="modalInfoRule" 
    @onClose="ok()"
    ></modal-info-rule>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ModalNewRule from './ModalNewRule.vue'
import ModalInfoRule from './ModalInfoRule.vue'
import AdvancedRulesService from './advancedRules.service.js'
import BaseServices from '@/store/services'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
export default {
  components: {ModalNewRule, ModalInfoRule},
  data () {
    return {
      controlHeight: { class: 'row mb-2 spacing-label-field'},
      buttonSend: {title: 'Buscar', icon: 'SearchIcon', color: 'warning'},
      keyFormRender: 0,
      currentRule: {},
      organizationId: null,
      form: {},
      filters: {},
      fields: [],
      buttons: [],
      buttonsDropDown: [],
      schema: [],
      rows: [],
      actions: [],
      selectedRows: [],
      pagination: {
        page: 1,
        total: 0,
        limit: 20
      },
      loading: {
        countries: true,
        organizations: true,
        shippersByOrganization: true,
        advancedRules: false,
        total: true,
        first: true,
        second: true,
        searchIcon: false
      },
      ruleTypeOptions: [
        {id: 1, html: 'Marketplace', value: true},
        {id: 3, html: 'Empresas', value: false},
        {id: 2, html: 'Enviame', value: false}
      ],
      statuses_active: [
        { id: 1, dot: 'table_dot--state-active', text: 'Activa para todo el Marketplace'},
        { id: 2, dot: 'table_dot--state-semi-active', text: 'Activa para algunos Sellers'},
        { id: 3, dot: 'table_dot--state-semi-active', text: 'Activa para algunas Empresas'},
        { id: 4, dot: 'table_dot--state-active', text: 'Activa para la Empresa'},
        { id: 5, dot: 'table_dot--state-creado', text: 'Inactiva'}
      ],
      advancedRulesService: new AdvancedRulesService(),
      baseService: new BaseServices(this),
      customTooltip: {},
      customeResponse: {},
      dataRule: {},
      isDelete: false
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      shippersByOrganization: 'getShippersByOrganizationSimplified',
      countries: 'getCountries',
      advancedRules: 'getAdvancedRules',
      advancedRulesByOrganization: 'getAdvancedRulesByOrganization',
      generalLoading: 'getLoading'
    })
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    },
    'generalLoading': {
      handler () {
        this.loading.countries = !!this.generalLoading.getCountries
        this.loading.shippersByOrganization = !!this.generalLoading.getShippersByOrganizationSimplified
        this.loading.advancedRules = !!this.generalLoading.getAdvancedRules
        this.loading.advancedRulesByOrganization = !!this.generalLoading.getAdvancedRulesByOrganization
      },
      deep: true
    },
    'loading': {
      handler () {
        const prevTotal = this.loading.total
        this.loading.total = !Object.keys(this.loading).filter(key => key !== 'total').every(key => !this.loading[key])
        if (prevTotal !== this.loading.total) this.keyFormRender++
      },
      deep: true
    },
    countries () { 
      this.setSelectOptions('country_id', { options: this.countries })
    },
    shippersByOrganization () {
      this.setSelectOptions('shipper_id', { options: this.shippersByOrganization })
    },
    advancedRules () {
      this.rows = this.advancedRules.rows.map(el => {
        return {
          ...el,
          name: `${el.name} - ${el._id}`,
          owner_company: `${el.owner.name} - ${el.owner.id}`,
          status_active: this.statuses_active.filter(status => el.rule_status === status.id)[0]
        }
      })
      this.pagination.total = this.advancedRules.total
      this.loading.second = false
    },
    advancedRulesByOrganization () {
      this.rows = this.advancedRulesByOrganization.rows.map(el => {
        return {
          ...el,
          owner_company: `${el.owner.name} - ${el.owner.id}`,
          status_active: this.statuses_active.filter(status => el.rule_status === status.id)[0]
        }
      })
      this.pagination.total = this.advancedRulesByOrganization.total
      this.loading.second = false
    },
    'pagination.page': {
      handler () {
        this.getAdvancedRules()
      }
    },
    'pagination.limit': {
      handler () {
        if (this.pagination.page === 1) { 
          this.getAdvancedRules()
        }
      }
    },
    'form.organization_id': {
      handler () {
        if (!this.form.organization_id) {
          this.rows = []
        }
      }
    }
  },
  mounted () {
    if (this.mySession.id) this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.customTooltip = {
        title: {text:'ESTADO DE ASIGNACIÓN DE REGLA'},
        items: [
          {text: '<b>ACTIVA PARA ALGUNAS EMPRESAS:</b> Estado de una regla creada por Envíame que se asigna a múltiples marketplaces, sellers o ecommerce.', style: {color: '#1E8E3E', opacity:'50%'}, id:1},
          {text: '<b>ACTIVA PARA TODO EL MARKETPLACE:</b> Estado de una regla a nivel organizacional, asignada independientemente del creador (ya sea el propio marketplace o envíame).', style: {color: '#1E8E3E'}, id:2},
          {text: '<b>ACTIVA PARA ALGUNOS SELLERS:</b> Estado de una regla creada por el marketplace para casos excepcionales y asignada a sellers específicos.', style: {color: '#1E8E3E', opacity:'50%'}, id:3},
          {text: '<b>ACTIVA PARA LA EMPRESA:</b> Estado de una regla creada por los sellers o el ecommerce para su propia tienda.', style: {color: '#1E8E3E'}, id:4},
          {text: '<b>INACTIVA:</b> Estado de una regla que no está actualmente en funcionamiento en el marketplace, sellers o ecommerce.', style: {color: '#D1DADE', opacity:'50%'}, id:5}
        ]
      }
      this.schema = [
        {label: 'Nombre de regla', sortable: true, key: 'name'},
        {label: 'Creador de regla', sortable: true, key: 'owner_company'},
        {label: 'Asignada A', sortable: true, useSlot: true, key: 'assigned'},
        {label: 'Estado', key: 'status', useSlot: true, toolTipIcon: 'InfoIcon', class:['icon-customize'], showToolTip: true, customizeTooltip: this.customTooltip},
        {label: 'Acciones', key: 'actions', class: ['text-center']}
      ]
      if (['admin', 'superadmin'].includes(this.$session.get('cas_user').role)) {
      // if (['marketplace'].includes(this.$session.get('cas_user').role))  {
        this.fields = [
          { fieldType: 'FieldSelect', label: this.$t('eCommerce/Marketplace'), name: 'organization_id', allowIdSearch: true, persistSearch: true, searchOnType: { fx: e => this.searchCompany(e), nChars: 3, debounce: 600 }, containerClass: 'col-sm-12 col-md-3 col-lg-3 col-xl-3', change: this.changeOrganizationAPI },
          {fieldType: 'FieldInput', name: 'name', label: 'Nombre de regla', useLabel: true, containerClass: 'col-sm-12 col-md-3 col-lg-3 col-xl-3', placeholder: 'Ingrese nombre de la regla' },
          { name: 'myButtons', useSlot: true, containerClass: 'col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-1-7-rem' }
        ]
        // this.form.rule_classification_id = this.ruleTypeOptions[0]
        // this.filters = {
        //   rule_classification_id: this.ruleTypeOptions[0].id
        // }
        this.advanceRuleService = 'AdvancedRuleByOrganization'
      } else if (['marketplace'].includes(this.$session.get('cas_user').role))  {
        this.customTooltip.items = [
          {text: '<b>ACTIVA PARA TODO EL MARKETPLACE:</b> Estado de una regla a nivel organizacional, asignada independientemente del creador (ya sea el propio marketplace o envíame).', style: {color: '#1E8E3E'}, id:2},
          {text: '<b>ACTIVA PARA ALGUNOS SELLERS:</b> Estado de una regla creada por el marketplace para casos excepcionales y asignada a sellers específicos.', style: {color: '#1E8E3E', opacity:'50%'}, id:3},
          {text: '<b>ACTIVA PARA LA EMPRESA:</b> Estado de una regla creada por los sellers o el ecommerce para su propia tienda.', style: {color: '#1E8E3E'}, id:4},
          {text: '<b>ACTIVA PARA ALGUNAS EMPRESAS:</b> Estado de una regla creada por Envíame que se asigna a múltiples marketplaces, sellers o ecommerce.', style: {color: '#1E8E3E', opacity:'50%'}, id:1},
          {text: '<b>INACTIVA:</b> Estado de una regla que no está actualmente en funcionamiento en el marketplace, sellers o ecommerce.', style: {color: '#D1DADE', opacity:'50%'}, id:5}
        ]
        
        this.organizationId = this.$session.get('cas_user').organization
        this.filters.organization_id = this.organizationId.id
        // } else if (['superadmin'].includes(this.$session.get('cas_user').role))  {
        // this.organizationId = { id: 10 }
        this.fields = [
          {fieldType: 'FieldSelect', name: 'shipper_id', label: 'Seller', useLabel: true, placeholder: 'Nombre del seller', clearable: true, containerClass: 'col-sm-12 col-md-6 col-lg-3 col-xl-3',
            change: this.changeShipper },
          {fieldType: 'FieldInput', name: 'name', label: 'Nombre de regla', useLabel: true, containerClass: 'col-sm-12 col-md-6 col-lg-3 col-xl-3', placeholder: 'Ingrese nombre de la regla' },
          { name: 'myButtons', useSlot: true, containerClass: 'col-sm-12 col-md-12 col-lg-6 mt-1-7-rem' }
        ]
        this.ruleTypeOptions = [
          {id: 1, html: 'Propias', value: true},
          {id: 3, html: 'Seller', value: false},
          {id: 2, html: 'Enviame', value: false}
        ]
        this.loading.first = false
        this.advanceRuleService = 'AdvancedRuleByOrganization'
        // this.form.rule_classification_id = this.ruleTypeOptions[0]
        // this.filters = {
        //   rule_classification_id: this.ruleTypeOptions[0].id
        // }
        this.getAdvancedRulesService = 'getAdvancedRulesByOrganization'
        this.getAdvancedRules()
        this.changeOrganization('Organization', this.organizationId)
      } else if (['ecommerce', 'seller'].includes(this.$session.get('cas_user').role)) {
        this.schema = [
          {label: 'Nombre de regla', sortable: true, key: 'name'},
          {label: 'Creador de regla', sortable: true, key: 'owner_company'},
          {label: 'Estado', key: 'status', useSlot: true},
          {label: 'Acciones', key: 'actions', class: ['text-center']}
        ]
        // } else if (['superadmin'].includes(this.$session.get('cas_user').role))  {
        // this.form.organization_id = { id: 9104, text: 'GLUO_LATAMPARTS'} // Prueba
        // this.form.shipper_id = { id: 9104, text: 'GLUO_LATAMPARTS'} // Prueba
        this.organizationId = this.$session.get('cas_user').organization
        this.filters.shipper_id = this.mySession.shipper.id
        this.filters.organization_id = this.organizationId.id
        this.form.shipper_id = { ...this.$session.get('cas_user').shipper, text: this.$session.get('cas_user').shipper.name1 }
        this.fields = [
          {fieldType: 'FieldSelect', name: 'shipper_id', label: 'Shipper', useLabel: true, placeholder: 'Nombre de la empresa', clearable: true, 
            change: this.changeShipper, disabled: true, validation: 'required' },
          {fieldType: 'FieldInput', name: 'name', label: 'Nombre de regla', useLabel: true, containerClass: 'col-sm-12 col-md-6 col-lg-3 col-xl-3', placeholder: 'Ingrese nombre de la regla' },
          { name: 'myButtons', useSlot: true, containerClass: 'col-sm-12 col-md-12 col-lg-6 mt-1-7-rem' }
        ]
        this.ruleTypeOptions = [
          {id: 1, html: 'Propias', value: true},
          {id: 3, html: 'Marketplace', value: false},
          {id: 2, html: 'Generales', value: false}
        ]
        // this.form.rule_classification_id = this.ruleTypeOptions[0]
        // this.filters = {
        //   rule_classification_id: this.ruleTypeOptions[0].id
        // }
        this.loading.first = false
        this.advanceRuleService = 'AdvancedRule'
        this.getAdvancedRulesService = 'getAdvancedRules'
        this.getAdvancedRules()
      }
      // this.fields.splice([this.getElementIndex(this.fields, 'myButtons')] + 1, 0,
      //   {
      //     fieldType: 'FieldRadio', 
      //     name: 'rule_classification_id', 
      //     containerClass: 'col-sm-12 col-md-12 mt-1', 
      //     align: 'h', 
      //     options: this.ruleTypeOptions, 
      //     change: this.changeCheckbox
      //   }
      // )
      this.buttons = [
        { name: 'delete', text: 'Eliminar', color: 'danger',  icon: 'TrashIcon', action: this.confirmMultipleAdvancedRules}
      ]
      this.buttonsDropDown = [
        { name: 'shipper', text: 'E-commerce', action: this.createAdvancedRuleShipper},
        { name: 'organization', text: 'Marketplace', action: this.createAdvancedRuleOrganization}
        // { name: 'general', text: 'General', action: this.createAdvancedRule(null, 'organization')}
      ]
      if (['marketplace'].includes(this.$session.get('cas_user').role))  {
        this.buttonsDropDown[this.buttonsDropDown.findIndex(el => el.name === 'organization')].text = 'Propia'
      }
      this.actions = [
        // {action: id => this.enableRule(id), switchOwnId:'active', dualState: {on: 'Habilitado', off: 'Deshabilitado', iconOn: 'PowerIcon', iconOff: 'PowerIcon'}},
        // {action: this.openEditModal, icon: 'Edit2Icon', color: 'warning', text: 'Editar' },
        // {action: this.openAdvancedRule, icon: 'SettingsIcon', color: 'success', text: 'Configurar' },
        {action: this.openAdvancedRule, icon: 'Edit2Icon', color: 'success', text: 'Editar' },
        {action: this.confirmDeleteAdvancedRule, icon: 'TrashIcon', color:'danger', text: 'Eliminar'}
      ]
      if (this.countries && !!this.countries.length) {
        this.setSelectOptions('country_id', { options: this.countries })
      }
      this.loading.first = false
      this.environment = this.advancedRulesService.setEnvironment()
    },
    getShipperOnSearch (name) {
      const queryParams = {
        name,
        page: 1,
        paginate_by: 9999
      }
      const params = {
        organization_id: !!this.organizationId ? this.organizationId?.id : this.form.organization_id?.id || 0
      }
      return this.advancedRulesService.callService('getShippersByOrganization', queryParams, params)
        .then(resp => {
          return resp.data.map(el => {
            el.text = `${el.id} - ${el.name1} - ${el.name2}`
            return el
          })
        })
    },
    savedRule () {
      this.$bvModal.hide('modalNewRule')
      this.currentRule = {}
      if (['marketplace'].includes(this.$session.get('cas_user').role)) { 
        this.form.shipper_id = null
        this.keyFormRender++
      } 
      this.getAdvancedRules()
    },
    cleanFilter (e) {
      this.form = {}
      if (['marketplace'].includes(this.$session.get('cas_user').role)) this.form.organization_id = this.organizationId.id
      this.keyFormRender++
      if (['admin', 'superadmin'].includes(this.$session.get('cas_user').role)) return 
      this.filterList(this.form)
    },
    filterList (form) {
      this.filters = {}
      Object.keys(form).map(key => {
        // if (key === 'organization_id') return
        // if (form[key]?.id === 3) {
        // this.filters['rule_classification_id'] = 1
        // if (['admin', 'superadmin', 'marketplace'].includes(this.$session.get('cas_user').role)) this.filters['only_sellers'] = true
        // if ((['ecommerce', 'seller'].includes(this.$session.get('cas_user').role))) this.filters['only_marketplace'] = true
        // } else  
        if (key === 'name' && form[key] !== '') { 
          this.filters[key] = form[key]
        } else if (form[key] !== null && form[key] !== '') this.filters[key] = form[key].id || form[key]
      })
      if (this.pagination.page !== 1) this.pagination.page = 1
      else this.getAdvancedRules()
    },
    setSelectOptions (name, { options }) {
      const index = this.fields.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fields[index].options = options
        this.keyFormRender++
      }
    },
    changeOrganization (name, value) {
      this.form = {
        ...this.form,
        shipper_id: null
      }
      const index = this.fields.findIndex(el => el.name === 'shipper_id')
      this.fields[index].options = []
      if (!value?.id) {
        return null
      }
      const queryParams = {
        page: 1,
        paginate_by: 99999
      }
      const params = {
        organization_id: !!this.organizationId ? this.organizationId.id : this.form.organization_id?.id || 0
      }
      this.fields[index].useSkeleton = true
      // this.keyFormRender++
      this.$store.dispatch('fetchService', { name: 'getShippersByOrganizationSimplified', queryParams, params, onSuccess: () => this.fields[index].useSkeleton = false})
    },
    changeCheckbox () {
      if ((this.form.rule_classification_id.id === 1 && this.form.organization_id !== null) && !['ecommerce', 'seller'].includes(this.$session.get('cas_user').role)) this.form.shipper_id = null
      this.ok()
    },
    openAdvancedRule (id) {
      const selectedRule = this.rows.find(el => el.id === id)
      const companyType = selectedRule.owner.shipper === true ? 'shipper' : 'organization'
      this.m_openWinBlank(`/advanced-rules/${companyType}/${selectedRule[`${companyType}_id`]}/${selectedRule._id}`)
    },
    
    createAdvancedRuleShipper () {
      this.m_openWinBlank('/advanced-rules/shipper/null/null')
    },
    createAdvancedRuleOrganization () {
      const url = ['admin'].includes(this.$session.get('cas_user').role) ? '/advanced-rules/organization/null/null' : `/advanced-rules/organization/${this.organizationId.id}/null`
      this.m_openWinBlank(url)
    },
    
    getAdvancedRules () {
      this.selectedRows = []
      const params = {}
      const data = {
        queryParams: { ...this.filters, limit: this.pagination.limit, page: this.pagination.page },
        onSuccess: () => this.loading.searchIcon = false,
        onError: () => this.loading.searchIcon = false
      }

      data.name = 'getAdvancedRules'
      // if (data.name === 'getAdvancedRulesByOrganization') {
      //   if (['admin', 'superadmin'].includes(this.$session.get('cas_user').role)) {
      //     // if (['marketplace'].includes(this.$session.get('cas_user').role)) {
      //     params.organization_id = this.form.organization_id.id
      //   } else if (['marketplace'].includes(this.$session.get('cas_user').role)) {
      //   // } else if (['admin', 'superadmin'].includes(this.$session.get('cas_user').role)) {
      //     params.organization_id = this.organizationId?.id
      //   // if (this.form.shipper_id && this.form.shipper_id !== null) queryParams.shipper_id = this.form.shipper_id.id
      //   }
      // } else if (data.name === 'getAdvancedRules') {
      //   params.shipper_id = this.form.shipper_id.id
      //   // data.name = 'getAdvancedRules'
      // }
      data.params = params
      this.$store.dispatch('fetchService', data)
    },
    deleteAdvancedRule (id) {
      const selectedRule = this.rows.find(el => el.id === id)
      const data = {
        onSuccess: this.getAdvancedRules
      }
      const params = { id: selectedRule._id }
      data.name = `delete${this.advanceRuleService}`
      if (['admin', 'superadmin', 'marketplace'].includes(this.$session.get('cas_user').role)) {
        params.organization_id = selectedRule.organization_id 
        if (selectedRule.owner.shipper) { 
          params.shipper_id = selectedRule.shipper_id
          data.name = 'deleteAdvancedRuleByOrganizationToShipper'
        }
      } else if (['ecommerce', 'seller'].includes(this.$session.get('cas_user').role)) {
        params.shipper_id = selectedRule.shipper_id
      }
      data.params = params
      this.$store.dispatch('fetchService', data)
    },
    confirmDeleteAdvancedRule (id) {
      const selectedRule = this.rows.find(el => el.id === id)
      this.openModal('modalInfoRule', selectedRule, true)
      // this.$yesno(this.$t('msg-pregunta-eliminar-elemento'), () => this.deleteAdvancedRule(id))
    },
    confirmMultipleAdvancedRules () {
      this.$yesno(this.$t('msg-pregunta-eliminar-seleccionados'), this.deleteMultipleAdvancedRules)
    },
    deleteMultipleAdvancedRules () {
      const services = this.selectedRows.map(id => {
        const _id = this.rows.find(el => el.id === id)._id
        if (this.rows.find(el => el.id === id).owner.shipper) {
          const shipper_id = this.rows.find(el => el.id === id).shipper_id
          return { name: 'deleteAdvancedRule', params: { id: _id, shipper_id } }
        } else if (!this.rows.find(el => el.id === id).owner.shipper) {
          const organization_id = this.rows.find(el => el.id === id).organization_id
          return { name: 'deleteAdvancedRuleByOrganization', params: { id: _id, organization_id } }
        }
      })
      this.$store.dispatch('fetchMultipleServices', { services, showPackSuccess: true, onSuccess: this.getAdvancedRules })
    },
    executeAction (btn) {
      if (btn.action) btn.action()
    },
    getElementIndex(array, value) {
      return array.findIndex(fieldsData => fieldsData.name === value)
    },
    async ok () {
      const checkForm = await this.$refs.formFilter.onlyCheckForm()
      if (checkForm) { 
        this.loading.searchIcon = true
        if (['marketplace'].includes(this.$session.get('cas_user').role)) this.form.organization_id = this.organizationId.id
        this.filterList(this.form)
      } 
    },
    // Endpoint with API-PLATFORM
    searchCompany(value) {
      const queryParams = {
        search_by: value
      }
      return this.baseService.callService('getCompaniesPlatform', queryParams)
        .then(resp => {
          const response = resp.data.filter(this.checkEcommerceOrMarketplace)
          const isAdmin = ['superadmin', 'admin'].includes(this.$session.get('cas_user')?.role)
          response.map((row) => {
            if (row.marketplace === 1 && isAdmin) {
              row.name1 = `${row.name1} (M) - ${row.id}`
              row.text = row.name1
              return row
            } else if (isAdmin && row.mkp_name1 === null && row.marketplace === 0) {
              row.name1 = `${row.name1} - ${row.id}`
              row.text = row.name1
              return row
            }
          })
          return response
        })
        .catch(err => {
          return err
        }) 
    },
    checkEcommerceOrMarketplace(organization) {
      if (organization.marketplace === 1) {
        return organization
      } else if (organization.marketplace === 0 && organization.mkp_name1 === null) {
        return organization
      }
    },
    changeOrganizationAPI (name, value) {
      this.form = {
        ...this.form,
        shipper_id: null
      }
      const isMKP = this.setIsMarketplace()
      //crear el input seller
      if (isMKP) {
        this.getAdvancedRulesService = 'getAdvancedRulesByOrganization'
        this.fields = this.fields.filter(field => field.name !== 'shipper_id')
        this.fields.splice(this.getElementIndex(this.fields, 'organization_id') + 1, 0, {fieldType: 'FieldSelect', label: this.$t('Sellers'), dependency: 'organization_id', allowIdSearch: true, searchOnType: { fx: e => this.searchSellers(e), nChars: 3, debounce: 600 }, name: 'shipper_id', change: this.changeShipperApi, containerClass: 'col-sm-12 col-md-4 col-lg-3 col-xl-3'})
        if (this.getElementIndex(this.fields, 'organization_id') !== -1) this.fields[this.getElementIndex(this.fields, 'organization_id')].containerClass = 'col-sm-12 col-md-4 col-lg-3 col-xl-3'
        if (this.getElementIndex(this.fields, 'name') !== -1) this.fields[this.getElementIndex(this.fields, 'name')].containerClass = 'col-sm-12 col-md-4 col-lg-2 col-xl-2'
        if (this.getElementIndex(this.fields, 'myButtons') !== -1) this.fields[this.getElementIndex(this.fields, 'myButtons')].containerClass = 'col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-1-7-rem'
      }
      //Eliminar seller si no es mkp y existe el input
      if (!isMKP) {
        this.getAdvancedRulesService = 'getAdvancedRules'
        if (this.getElementIndex(this.fields, 'shipper_id') !== -1) this.fields.splice(this.getElementIndex(this.fields, 'shipper_id'), 1)
        if (this.getElementIndex(this.fields, 'name') !== -1) this.fields[this.getElementIndex(this.fields, 'name')].containerClass = 'col-sm-12 col-md-3 col-lg-3 col-xl-3'
        if (this.getElementIndex(this.fields, 'myButtons') !== -1) this.fields[this.getElementIndex(this.fields, 'myButtons')].containerClass = 'col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-1-7-rem'
        this.form.shipper_id = this.form.organization_id
      }
    },
    changeShipperApi (name, value) {
      // if (value === null) {
      //   this.form.rule_classification_id = this.ruleTypeOptions[0]
      // } else this.form.rule_classification_id = this.ruleTypeOptions[1]
    },
    searchSellers (value) {
      const params = {
        company_id: this.form.organization_id.id
      }
      const queryParams = {
        search_by: value
      }
      return this.baseService.callService('getSellersPlat', queryParams, params)
        .then(resp => {
          const response = resp.data.companies
          response.map((row) => {
            Object.keys(row).forEach((key) => {
              return row[key] = `${row[key]}`
            })
            row.text = `${row.name1} - ${row.id}`
          })
          return response
        })
        .catch(err => {
          return err
        }) 
    },
    setIsMarketplace () {
      if (['superadmin', 'admin'].includes(this.$session.get('cas_user')?.role)) {
        return this.form?.organization_id ? Number(this.form.organization_id.marketplace) : 0
      }  else {
        return 0
      }
    },
    async openModal(nameId, info, isDelete) {
      const ruleId = info._id
      this.dataRule = {
        rule_id: ruleId,
        name: info.name,
        organization_id: this.form.organization_id?.id,
        owner: info.owner,
        rule_status: info.rule_status
      }
      this.isDelete = isDelete
      this.$bvModal.show(nameId)
    }
  }
}
</script>
<style lang="scss" scope>
div[class^="advanced-rules-button"] {
  top: -3.5rem !important;
  right: 0rem !important;
  position: absolute !important;
  z-index: 9;
}
.mt-1-7-rem {
  margin-top: 1.65rem;
}
.custom-control-inline {
  margin-right: 5rem;
}
.icon-customize svg{
  color: #0169DE;
}
.icon-customize svg:hover{
  background-color: #0169DE;
  color: white;
  border-radius: 0.5em;
  border-color:#0169DE;
}
.table.b-table > * > tr > *:first-child {
    padding-left: 3rem !important;
}
.underline-text-deliveries{
  text-decoration: underline !important;
}
</style>