<template>
  <div class="company-dialog">
    <b-modal id="modalNewRule" centered modal-class="modal-warning" size="sm"
    @ok="ok" ok-variant="warning" :ok-title="$t(`${editing ? 'Modificar' : 'Crear'} regla`)" ok-only
    :title="title">
      <div class="mt-0">
        <form-render :fields.sync="fields" :form.sync="organization" @send="onAccept" id="formNewRule"
          ref="formRenderNewRule"
          containerButtonsClass="col-sm-12 col-lg-4 container-button">
        </form-render>
      </div>
    </b-modal>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'
export default {
  props: ['form', 'title'],
  data () {
    return {
      organization: {},
      fields: [],
      editing: false,
      buttonSend: {text: 'Filtrar', icon: 'SearchIcon', color: 'warning'},
      optionsActive: [{id: 1, text: 'Activo'}]
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
      // countries: 'getCountries',
      // organizationTypes: 'getOrganizationTypes'
    })
  },
  mounted () {
    this.setInitialData()
    // this.setCountries()
    // this.setOrganizationTypes()
  },
  watch: {
    form () {
      this.organization = {
        ...this.form,
        active: this.form.active ? [{id: 1, text: 'Activo'}] : []
      }
      this.editing = !!this.form.id
    },
    mySession () {
      if (this.mySession.id) this.setInitialData()
    }
  },
  methods: {
    setInitialData () {
      if (['admin', 'superadmin', 'marketplace'].includes(this.$session.get('cas_user').role)) {
        this.advanceRuleService = 'AdvancedRuleByOrganization'
      } else if (['ecommerce', 'seller'].includes(this.$session.get('cas_user').role)) { 
        this.advanceRuleService = 'AdvancedRule'
      }
      this.fields = [
        {fieldType: 'FieldInput', label: 'Nombre', name: 'name', containerClass: 'col-12 container-info', validation: 'required'},
        {fieldType: 'FieldCheckbox', multiple: true, name: 'active', containerClass: 'col-12 container-info', options: this.optionsActive}
      ]
    },
    onAccept (form) {
      const rule = {
        name: form.name,
        active: !!form.active?.length
      }
      const data = {
        // name: this.form?.id ? 'updateAdvancedRule' : 'postAdvancedRuleByOrganization',
        // params: { id: this.form?.id, organization_id: this.form.organization_id.id },
        queryParams: { ...rule },
        onSuccess: () => this.$emit('send', true)
      }
      const params = {}
      if (this.form?.id) {
        data.name = `update${this.advanceRuleService}`
        params.id = this.form?.id
        if (['admin', 'superadmin', 'marketplace'].includes(this.$session.get('cas_user').role)) {
          params.organization_id = this.form.organization_id 
          if (this.form.owner.shipper) { 
            params.shipper_id = this.form.shipper_id
            data.name = 'updateAdvancedRuleByOrganizationToShipper'
          }
        } else if (['ecommerce', 'seller'].includes(this.$session.get('cas_user').role)) {
          params.shipper_id = this.form.shipper_id
        }
      } else {
        data.name = `post${this.advanceRuleService}`
        if (['admin', 'superadmin', 'marketplace'].includes(this.$session.get('cas_user').role)) {
          params.organization_id = this.form.organization_id.id
          if (this.form.shipper_id) { 
            params.shipper_id = this.form.shipper_id.id
            data.name = 'postAdvancedRuleByOrganizationToShipper'
          }
        } else if (['ecommerce', 'seller'].includes(this.$session.get('cas_user').role)) {
          params.shipper_id = this.form.shipper_id.id
        }
      }
      data.params = params
      this.$store.dispatch('fetchService', data)
    },
    ok (e) {
      e.preventDefault()
      this.$refs.formRenderNewRule.checkForm()
    }
  }
}
</script>
<style>

</style>
