<template>
  <div>
    <b-modal ref="modalInfoRule" @close="onClose()" id="modalInfoRule" no-close-on-backdrop centered
      modal-class="dialog-800" hide-footer :title="ruleTitle">
      <section v-show="!showValidateProcess && !showAssignCompleted && !showDeleteProcess">
        <section class="info-modal-section">
          <section>
            <b-button @click="exportListCompaniesOnRule()" style="float: right; padding: 1em; margin: 1em;"
              class="col-sm-4" variant="outline-success">
              <b-spinner small style="vertical-align: top" v-show="loading.exportListButton" /><feather-icon
                v-show="!loading.exportListButton" icon="DownloadCloudIcon"></feather-icon> {{ !loading.exportListButton
                  ? 'Exportar listado' : ''}}
            </b-button>
          </section>
          <section>
            <span class="span-customize"><b><u>Listado Empresas Asignadas {{dataRule.rule_status != 1 ? `(${qtyCompaniesAssigned})` : ''}}</u></b></span>
            <p class="text-modal-section">
              Puedes explorar las empresas asignadas a esta regla utilizando el buscador. Si no encuentras la empresa
              que
              buscas, puedes agregarla a esta regla.
            </p>
          </section>
        </section>
        <form-render :fields="fields" :form.sync="form">
          <template #custom-add>
            <b-button @click="checkRuleAssigned()" v-show="showAddButton" variant="outline-primary">
              <b-spinner small style="vertical-align: top" v-show="loading.userPlusIcon" />
              <feather-icon v-show="!loading.userPlusIcon" icon="UserPlusIcon">
              </feather-icon>
              {{ !loading.userPlusIcon ? 'Asignar Empresa' : '' }}
            </b-button>
          </template>
        </form-render>
        <table-render :emptyHtml="emptyTableMsg" class="customize-table-render" :schema="schema"
          :rows.sync="form.organization_id ? rowsFiltered : rows" v-show="!loading.first">
        </table-render>
        <pagination :pagination="pagination" :noDigits="true" :showSize="true" v-show="!loading.first" />
        <div class="table-render-skeleton" v-show="loading.first">
          <b-skeleton-table v-show="loading.first" :rows="10" :columns="schema.length || 1" :table-props="{}" />
        </div>
      </section>
      <section v-show="showValidateProcess && !showAssignCompleted && !showDeleteProcess" class="container-add-process">
        <feather-icon icon="AlertTriangleIcon" class="warning-icon"></feather-icon>
        <section class="msg-add-process">
          <p v-show="currentRuleAsigned === null">
            La empresa <b>"{{ currentSelectShipperName }}"</b> actualmente no tiene una regla asignada
          </p>
          <p v-show="currentRuleAsigned">
            La empresa <b>"{{ currentSelectShipperName }}"</b> actualmente esta asignada a <b>"{{ currentRuleAsigned
              }}"</b>
          </p>
        </section>
        <p>¿Esta seguro que desea agregarla a {{ ruleTitle }}?</p>
        <section class="container-validate-buttons">
          <b-button @click="cancelProcessToAddShipper()" variant="outline-danger" class="col-sm-4">NO</b-button>
          <b-button @click="assignShipperToRule()" variant="outline-success" class="col-sm-4"><b-spinner small
              style="vertical-align: top" v-show="loading.yesButton" />{{ !loading.yesButton ? 'SI' : '' }}</b-button>
        </section>
      </section>
      <section v-show="!showValidateProcess && showAssignCompleted && !showDeleteProcess" class="container-add-process">
        <feather-icon icon="CheckCircleIcon" class="complete-icon"></feather-icon>
        <section class="msg-add-process">
          <p>
          La empresa <b>"{{ currentSelectShipperName }}"</b> ha sido exitosamente asignada a {{ ruleTitle }}
        </p>
        </section>
        <p>
          Es importante informarle que ya no estará asociada a "{{ currentRuleAsigned
          }}".
        </p>
        <section class="container-validate-buttons">
          <b-button @click="onClose()" variant="outline-warning" class="col-sm-4">Aceptar</b-button>
        </section>
      </section>
      <section v-show="!showValidateProcess && !showAssignCompleted && showDeleteProcess" class="container-add-process">
        <feather-icon icon="AlertTriangleIcon" class="warning-icon"></feather-icon>
        <section class="msg-add-process">
          <p v-show="currentRuleAsigned === null">
          ¿ Esta seguro de eliminar la regla <b>{{ ruleTitle }}</b>?
        </p>
        </section>
        <p>
          Ten en cuenta que al hacerlo, podría afectar la operación actual, dejando a {{ qtyCompaniesAssigned }}
          empresas sin
          regla operativa.
        </p>
        <section class="container-validate-buttons">
          <b-button @click="onClose()" variant="outline-danger" class="col-sm-4">NO</b-button>
          <b-button @click="deleteRule()" variant="outline-success" class="col-sm-4"><b-spinner small
              style="vertical-align: top" v-show="loading.yesButton" />{{ !loading.yesButton ? 'SI' : '' }}</b-button>
        </section>
      </section>
    </b-modal>
  </div>
</template>
<script>
import AdvancedRulesService from './advancedRules.service'
import BaseServices from '@/store/services'
export default {
  data() {
    return {
      schema: [],
      rows: [],
      fields: [],
      form: {},
      advancedRulesService: new AdvancedRulesService(),
      baseService: new BaseServices(),
      resultsFiltered: [],
      rowsFiltered: [],
      pagination: {
        page: 1,
        total: 0,
        limit: 20
      },
      loading: {
        first: true,
        userPlusIcon: false,
        yesButton: false,
        exportListButton: false
      },
      infoRule: {},
      qtyCompaniesAssigned: 0,
      emptyTableMsg: null,
      showAddButton: false,
      currentRuleAsigned: null,
      showValidateProcess: false,
      servicesToUpdate: {
        0: 'updateRuleAssignedSeller',
        1: 'updateRuleAssignedOrganization'
      },
      showAssignCompleted: false,
      resultAssign: null,
      showDeleteProcess: false
    }
  },
  props: {
    dataRule: {
      type: Object,
      require: true
    },
    isDelete: {
      type: Boolean,
      require: false,
      default: false
    }

  },
  methods: {
    setInitialData() {
      this.schema = [
        { label: 'Nombre de empresa', key: 'shipper_name' }
      ]
      this.fields = [
        { fieldType: 'FieldSelect', label: this.$t('Nombre de empresa'), name: 'organization_id', validation: 'required', allowIdSearch: true, persistSearch: true, searchOnType: { fx: e => this.searchCompany(e), nChars: 3, debounce: 600 }, containerClass: 'col-sm-6', change: () => this.searchCompanyOnRule() },
        { name: 'custom-add', useSlot: true, containerClass: 'col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-1-7-rem custom-add' }
      ]
    },
    getListOfShippersOnRule(queryParams, params) {
      if (queryParams.export === true) {
        return this.advancedRulesService.callService('getShippersAssigned', queryParams, params)
          .then(resp => {
            const title = `¡La exportación del listado de empresas asignadas a '${this.ruleTitle}' se ha completado con éxito!`
            const msg = 'En breve recibirá un correo electrónico con el archivo adjunto.'
            this.loading.exportListButton = false
            this.$newSuccess(title, msg, 'large')
            return resp
          }).catch(err => {
            return err
          })
      } else {
        return this.advancedRulesService.callService('getShippersAssigned', queryParams, params)
          .then(resp => {
            return resp
          }).catch(err => {
            return err
          })
      }
    },
    searchCompany(value) {
      const queryParams = {
        search_by: value,
        marketplace_id: this.dataRule.organization_id
      }
      return this.baseService.callService('getCompaniesPlatform', queryParams)
        .then(resp => {
          const response = resp.data
          const isAdmin = ['superadmin', 'admin'].includes(this.$session.get('cas_user')?.role)
          response.map((row) => {
            row.name1 = `${row.name1} - ${row.id}`
            row.text = row.name1
            return row
          })
          return response
        })
        .catch(err => {
          return err
        })
    },
    exportListCompaniesOnRule() {
      const queryParams = {
        export: true
      }
      const params = {
        rule_id: this.dataRule.rule_id
      }
      this.loading.exportListButton = true
      this.getListOfShippersOnRule(queryParams, params)
    },

    async searchCompanyOnRule() {
      if (this.form.organization_id) {
        const queryParams = {
          search: this.form.organization_id.id
        }
        const params = {
          rule_id: this.dataRule.rule_id
        }
        this.loading.first = true
        this.showAddButton = false
        this.resultsFiltered = await this.getListOfShippersOnRule(queryParams, params)
      }
    },
    checkRuleAssigned() {
      const queryParams = {}
      const params = {
        company_id: this.form.organization_id.id
      }
      this.loading.userPlusIcon = true
      this.baseService.callService('getCompanyRules', queryParams, params)
        .then(resp => {
          resp.data ? this.currentRuleAsigned = resp.data.name : this.currentRuleAsigned = null
          this.showValidateProcess = true
          this.loading.userPlusIcon = false
        })
        .catch(err => {
          this.$alert(this.$t('msg-problema-cargar', { code: err }))
        })
    },
    onClose() {
      this.form = {}
      this.resultsFiltered = []
      this.rowsFiltered = []
      this.pagination = {
        page: 1,
        total: 0,
        limit: 20
      }
      this.loading = {
        first: true,
        userPlusIcon: false,
        yesButton: false,
        exportListButton: false
      }
      this.infoRule = {}
      this.qtyCompaniesAssigned = 0
      this.emptyTableMsg = null
      this.showAddButton = false
      this.currentRuleAsigned = null
      this.showValidateProcess = false
      this.showAssignCompleted = false
      this.$emit('onClose')
      this.$bvModal.hide('modalInfoRule')

    },
    cancelProcessToAddShipper() {
      this.showValidateProcess = false
      this.showAssignCompleted = false
      this.form = {}
    },
    async assignShipperToRule() {
      const params = {
        organization_id: this.dataRule.organization_id,
        shipper_id: parseInt(this.form.organization_id.id),
        rule_id: this.dataRule.rule_id
      }
      const queryParams = {}
      this.loading.yesButton = true
      this.resultAssign = await this.assignRule(queryParams, params, this.servicesToUpdate[this.form.organization_id.marketplace || 0])
    },
    assignRule(queryParams, params, serviceToCall) {
      return this.advancedRulesService.callService(serviceToCall, queryParams, params)
        .then(resp => {
          this.showAssignCompleted = true
          this.showValidateProcess = false
          // this.resultAssign = resp
          this.yesButton = false
          return resp
        }).catch(err => {
          this.yesButton = false
          return err
        })
    },
    deleteRule() {
      const params = {
        id: this.dataRule.rule_id
      }
      const data = {
        onSuccess: () => {
          this.$emit('onClose')
          this.$newSuccess(`La regla "${this.dataRule.name}" ha sido eliminada`, 'La regla ha sido eliminada sin inconvenientes`', 'large')
        },
        onError: (err) => this.$newError('Ha ocurrido un problema', err.msg, 'large')
      }
      if (this.dataRule.owner.shipper) {
        data.name = 'deleteAdvancedRule'
        params.shipper_id = this.dataRule.owner.id
      } else {
        data.name = 'deleteAdvancedRuleByOrganization'
        params.organization_id = this.dataRule.organization_id
      }
      data.params = params
      this.$store.dispatch('fetchService', data)
      this.onClose()
    }
  },
  mounted() {
    this.setInitialData()
  },
  watch: {
    async dataRule() {
      this.infoRule = await this.getListOfShippersOnRule({}, { rule_id: this.dataRule.rule_id })
    },
    infoRule() {
      if (this.infoRule.data) {
        this.rows = this.infoRule.data.filter((row) =>  row.shipper !== null)
        this.rows = this.rows.map((row) => {
          return {shipper_name: row.shipper.name.concat(' - ', row.shipper.id), id: row.shipper.id } 
        })
      }
      this.pagination.total = this.infoRule.meta ? this.infoRule.meta.pagination.total : 0
      this.qtyCompaniesAssigned = this.pagination.total
      this.loading.first = false
    },
    resultsFiltered() {
      if (this.resultsFiltered.data) {
        this.rowsFiltered = this.resultsFiltered.data.map((row) => {
          return { shipper_name: row.shipper.name.concat(' - ', row.shipper.id), id: row.shipper.id }
        })
        this.rowsFiltered.length === 0 ? this.showAddButton = true : this.showAddButton = false
        this.loading.first = false
      }
    },
    'pagination.page': {
      async handler() {
        this.loading.first = true
        const queryParams = {
          limit: this.pagination.limit,
          page: this.pagination.page
        }
        this.infoRule = await this.getListOfShippersOnRule(queryParams, { rule_id: this.dataRule.rule_id })
      }
    },
    'pagination.limit': {
      async handler() {
        this.loading.first = true
        const queryParams = {
          limit: this.pagination.limit,
          page: this.pagination.page
        }
        this.infoRule = await this.getListOfShippersOnRule(queryParams, { rule_id: this.dataRule.rule_id })
      }
    },
    'form.organization_id': {
      handler() {
        !this.loading.first && this.form.organization_id ? this.showAddButton = true : this.showAddButton = false
        this.form.organization_id ? this.emptyTableMsg = `La empresa <b> ${this.form.organization_id.name1} </b> no se encuentra asignada a la regla` : this.emptyTableMsg = null
      }
    },
    isDelete() {
      this.showDeleteProcess = this.isDelete
      if (this.showDeleteProcess) {
        this.showValidateProcess = false
        this.showAssignCompleted = false
      }
    }
  },
  computed: {
    ruleTitle() {
      return this.dataRule.name
    },
    currentSelectShipperName() {
      return this.form.organization_id ? this.form.organization_id.name1 : null
    }
  }
}
</script>
<style>
.info-modal-section {
  display: flex;
  flex-direction: column;
}

.text-modal-section {
  color: #044389;
}

.customize-table-render {
  margin: 1.5em 0.1em !important;
  border: solid 1px #EBE9F1;
}

.customize-table-render tr {
  background-color: #FAFAFC;
}

.span-customize {
  color: #044389;
}

.custom-add svg {
  margin-right: 0.4em;
}

.warning-icon {
  color: #F18805;
  height: 10em;
  width: 10em;
  margin: auto;
  margin-bottom: 2em;
  stroke-width: 0.8;

}

.container-add-process {
  display: flex;
  flex-direction: column;
  padding: 2em 0.5em 12em;
  text-align: center;
}

.container-validate-buttons {
  display: flex;
  justify-content: space-evenly;
  padding: 1em;
}

.complete-icon {
  /* color: #044389; */
  color: green;
  height: 10em;
  width: 10em;
  margin: auto;
  margin-bottom: 2em;
  /* stroke-width: 0.8; */
}
</style>